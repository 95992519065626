import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer/Footer";
import InstagramFeed from "../../Components/InstagramFeed/InstagramFeed";
import GoogleReviews from "../../Components/GoogleReviews/GoogleReviews";
import HomeQRCode from "../../Components/HomeQRCode/HomeQRCode";
import WhatsAppChat from "../../Components/WhatsAppChat/WhatsAppChat";
import MyGallery from "../../Components/Gallery/Gallery";
import ServicesSlider from "../../Components/ServiceSlide/ServiceSlide";
import Slider from "../../Components/Carousel/Slider";
import styled from "@emotion/styled";
import tagImg from "../../Assets/Images/tag.svg";
import locationImg from "../../Assets/Images/location.svg";
import bookImg from "../../Assets/Images/book.svg";
import GalleryFilter from "../../Components/Gallery/GalleryFilter";
import Map from "../../Components/Map/Map";

import { Fab } from "@mui/material";
import { FaArrowAltCircleUp } from "react-icons/fa";
import HomeAbout from "../../Components/HomeAbout/HomeAbout";

import HomeCSS from "./Home.module.css";
import useAuth from "../../Hooks/useAuth";
import { Helmet } from "react-helmet-async";
import BeforeAfter from "../../Components/BeforeAfter/BeforeAfter";
import QrCodeDialog from "../../Components/QrCodeDialog/QrcodeDialog";

const StyledAdContainer = styled.div`
  @font-face {
    font-family: myHeadFont;
    src: url(../../Assets/fonts/raph.ttf);
  }

  padding-top: 2rem;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fff;

  h3 {
    // color: #4d4d4d;
    font-size: 2.3rem;
    font-weight: 700;
    margin-bottom: 2rem;
    font-family: "myHeadFont", sans-serif;
  }
  @media (max-width: 400px) {
    h3 {
      text-align: center;
    }
  }
`;
const StyledAdContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 85%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  align-items: center;
  div {
  }

  @media (max-width: 480px) {
    width: 100%;
    padding: 0 1rem;
  }
`;
const StyledAdContentImg = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;

  h6 {
    font-size: 0.6rem;
    font-weight: bold;
    color: #4d4d4d;
  }
  p {
    font-size: 0.5rem;
  }
  @media (max-width: 480px) {
    h6 {
      font-size: 0.5rem;
    }

    div {
      height: 50px;
      width: 50px;
    }
  }
`;

const StyledWrapperBg = styled.section`
  background-color: #f2f5f9;
  width: 100%;
`;

const StyledGalleryHeader = styled.div`
  margin-bottom: 2rem;
  h1 {
    font-size: 3rem;
    font-weight: 700;
    text-align: center;
    width: 100%;
    margin: 1rem 0 1rem;
    color: #fff;
  }

  p {
    color: #fff;
    text-align: center;
  }
`;

const StyledCurve = styled.div`
  width: 100%;
  // border-color: none
  height: 30px;
  background: #5b5754;
  .curve {
    width: 100%;
    height: 30px;
    background-color: #f2f5f9;
    width: 100%;
  }
  @media screen and (max-width: 960px) {
    height: 20px;
    .curve {
      height: 20px;
      border-bottom-right-radius: 30px;
      border-bottom-left-radius: 30px;
    }
  }
`;
const StyledCurveWithQRCode = styled.div`
  width: 100%;
  // border-color: none
  height: 30px;
  margin-top: 2rem;
  background-color: #f2f5f9;
  .curve {
    background: #5b5754;
    width: 100%;
    height: 30px;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    width: 100%;
  }
  @media screen and (max-width: 960px) {
    height: 20px;
    .curve {
      height: 20px;
      border-top-right-radius: 30px;
      border-top-left-radius: 30px;
    }
  }
`;
const StyledCurveBA = styled.div`
  width: 100%;

  height: 30px;
  background: #000;
  .curve {
    width: 100%;
    height: 30px;
    background-color: #f2f5f9;
    width: 100%;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
  }
  @media screen and (max-width: 960px) {
    height: 20px;
    .curve {
      height: 20px;
      border-top-right-radius: 30px;
      border-top-left-radius: 30px;
    }
  }
`;
const StyledCurveIntro = styled.div`
  width: 100%;

  height: 30px;
  background: #fff;
  .curve {
    -webkit-backdrop-filter: blur(9.3px);
    width: 100%;
    height: 30px;
    border-top: 1px solid #dee1e5;
    background-color: #f2f5f9;
    width: 100%;
    border-top-right-radius: 26px;
    border-top-left-radius: 26px;
  }
`;
const StyledTestimonialCurve = styled.div`
  width: 100%;

  height: 30px;
  background: #5b5754;
  .curve {
    width: 100%;
    height: 30px;
    border-bottom: 1px solid #f2f5f9;
    background-color: #f2f5f9;
    width: 100%;
    border-top-right-radius: 26px;
    border-top-left-radius: 26px;
  }
`;

const StyledTextContainer = styled.div`
  max-width: 1500px;
  width: "85%";
  // backgroundColor: #F2F5F9,
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: center;
  padding: 0 1rem;

  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
  @media screen and (min-width: 600px) and (max-width: 960px) {
    font-size: 18px;
  }
  @media screen and (min-width: 960px) and (max-width: 1920px) {
    font-size: 22px;
  }
  @media screen and (min-width: 1920px) {
    font-size: 24px;
  }
`;

const StyledQrCodeBA = styled.div`
  @font-face {
    font-family: myHeadFont;
    src: url(../../Assets/fonts/raph.ttf);
  }
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;

  .before__after {
    display: flex;
    justify-content: center;
  }
  .qr__code {
    display: flex;
    // cursor: pointer;
    // justify-content: center;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
    padding: 0 1.5rem;
    h1 {
      margin-bottom: 1rem;
      font-family: "myHeadFont", san-serif;
      font-size: 2.3rem;
      text-align: center;
    }
  }

  @media (min-width: 900px) {
    .before__after {
      // padding-left: 0.3rem;
    }
  }
  @media (max-width: 900px) {
    grid-template-columns: 100%;

    // .qr__code:nth-child(1) {
    //   grid-column: 2;
    // }
    // .before__after:nth-child(2) {
    //   grid-column: 1;
    // }

    .qr__code {
      h1 {
        margin-top: 1rem;
      }
      h2 {
        text-align: center;
      }
    }
  }
`;

export default function Home() {
  const { auth } = useAuth();
  const [open, setOpen] = React.useState(false);
  // const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };
  return (
    <>
      <Helmet>
        <title>Beauty Salon in Maryland, USA</title>
        <meta
          name="description"
          content="Experience luxury beauty services in Maryland, USA. We offer expert hair styling, makeup artistry, body sculpting, brow fixing, and teeth whitening."
        />
        <meta
          name="keywords"
          content="beauty salon, hair styling, makeup artistry, body sculpting, brow fixing, teeth whitening, Maryland, USA"
        />
        <script>
          {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '532804699038962');
          fbq('track', 'PageView');
          `}
        </script>
        <noscript>
          {`
          
          <img
            height="1"
            width="1"
            style={{ display: "none" }}
            alt="DanRit"
            src="https://www.facebook.com/tr?id=532804699038962&ev=PageView&noscript=1"
          />
          `}
        </noscript>

        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-EJ8VF2YNW0"
        ></script>

        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-EJ8VF2YNW0');
            `}
        </script>
      </Helmet>
      {Object.keys(auth).length > 0 ? (
        auth.role === 3 || auth.role === 1 ? (
          <> </>
        ) : (
          <WhatsAppChat />
        )
      ) : (
        <WhatsAppChat />
      )}

      <Slider />

      <StyledWrapperBg>
        <StyledAdContainer>
          <h3>The Brighter Way To Book Beauty</h3>

          <StyledAdContent>
            <StyledAdContentImg>
              <div>
                <img src={tagImg} loading="lazy" alt="tag" srcset="" />
              </div>
              <h6>Smart prices</h6>
              <p>Just book last-minute, or off-peak</p>
            </StyledAdContentImg>
            <StyledAdContentImg>
              <div>
                <img loading="lazy" src={bookImg} alt="tag" srcset="" />
              </div>

              <h6>Book 24/7</h6>
              <p>Book from bed or bus.</p>
            </StyledAdContentImg>
            <StyledAdContentImg>
              <div>
                <img loading="lazy" src={locationImg} alt="tag" srcset="" />
              </div>
              <h6>Choice of top-rated salons</h6>
              <p>Thousands of venues (and reviews)</p>
            </StyledAdContentImg>
          </StyledAdContent>
        </StyledAdContainer>

        <StyledCurveIntro>
          <div className="curve"></div>
        </StyledCurveIntro>
        <ServicesSlider />
        <HomeAbout />
        {/*  */}
        <div
          style={{
            width: "100%",
            background: "#000",
            paddingBottom: "2rem",
            paddingTop: "2rem",
          }}
        >
          <StyledQrCodeBA>
            <div className="qr__code"  >
              <p className={HomeCSS.b_and_a_head}>Before And After Visit</p>
              <p className={HomeCSS.b_and_a_txt}>
                <span style={{ color: "#fff", fontWeight: "bold" }}>
                  Before:
                </span>{" "}
                Tired of the daily battle with frizz, split ends, and lackluster
                locks? Your hair deserves a makeover that'll leave you feeling
                confident and gorgeous. Our experienced stylists are here to
                work their magic on your mane, whether it's a complete
                transformation or just a touch-up.
                <br />
                <br />
                <span style={{ color: "#fff", fontWeight: "bold" }}>
                  {" "}
                  After:
                </span>{" "}
                Prepare to be amazed as your hair is revitalized,
                and radiates with health and shine. Our expert team will leave
                you with a luxurious head of hair that turns heads wherever you
                go. Say goodbye to bad hair days and hello to the hair you've
                always dreamed of. Experience the ultimate hair transformation
                journey with us today.
              </p>
              {/* <button className="home_about-btn">About Us</button> */}
            </div>
            <QrCodeDialog onClose={handleClose} open={open} />
            <div className="before__after">
              <BeforeAfter
                secondIMG={
                  process.env.PUBLIC_URL + "/beforeandafter/before-2.jpeg"
                }
                firstIMG={
                  process.env.PUBLIC_URL + "/beforeandafter/before-1.jpeg"
                }
                showHeader={false}
              />
            </div>
          </StyledQrCodeBA>
        </div>
        <StyledCurveBA>
          <div className="curve"></div>
        </StyledCurveBA>

        <StyledQrCodeBA>
          <div className="before__after">
            <BeforeAfter
              showHeader={true}
              firstIMG="https://files.elfsightcdn.com/eafe4a4d-3436-495d-b748-5bdce62d911d/4620a1ba-8dcc-4010-86e6-23528e21a5f3/WhatsApp-Image-2023-07-10-at-9-37-54-AM.jpg"
              secondIMG="https://files.elfsightcdn.com/eafe4a4d-3436-495d-b748-5bdce62d911d/d00a37f6-fc0c-448b-80dd-48e8df0d2f27/WhatsApp-Image-2023-07-10-at-9-37-54-AM-1-.jpg"
            />
          </div>
          <div className="qr__code">
            <h1>Discover Our Social Channels</h1>
            <div style={{ display:"flex", marginLeft:"auto",marginRight:"auto"}} onClick={handleClickOpen}>
              <HomeQRCode />
            </div>

            <QrCodeDialog onClose={handleClose} open={open} />
          </div>
        </StyledQrCodeBA>
        <StyledCurveWithQRCode>
          <div className="curve"></div>
        </StyledCurveWithQRCode>
        <div
          style={{
            width: "100%",
            background: "#5B5754",
            paddingBottom: "2rem",
            paddingTop: "1rem",
          }}
        >
          <StyledGalleryHeader>
            <h1 className={HomeCSS.headers_f}>Gallery</h1>

            <StyledTextContainer>
              <p>
                Experience beauty at its finest through our captivating Gallery.
                Discover our salon's exquisite interior, talented stylists, and
                remarkable transformations. Get inspired and trust us to bring
                your beauty aspirations to life.
              </p>
            </StyledTextContainer>
          </StyledGalleryHeader>

          <GalleryFilter />
        </div>
        <ScrollToTop />

        <StyledTestimonialCurve>
          <div className="curve"></div>
        </StyledTestimonialCurve>
        <InstagramFeed />
        <h1 className={HomeCSS.testimonial_width}>Testimonials</h1>
        <GoogleReviews />
      </StyledWrapperBg>

      <Map />
      <Footer />
    </>
  );
}

const StyledFab = styled(Fab)`
  position: fixed;

  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;
  z-index: 2;
`;

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    setIsVisible(scrollTop > 500);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <button
      className={`${HomeCSS["scroll-to-top"]} ${
        isVisible ? HomeCSS["visible"] : ""
      }`}
      onClick={scrollToTop}
    >
      <FaArrowAltCircleUp color="#FC2F32" fontSize="3rem" />
    </button>
  );
};
